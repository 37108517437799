<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5"
             v-if="booking">
          <div class="col-12">
            <UserTripMenu :status="booking.status" />
          </div>
        </div>
        <div class="row"
             v-if="booking">
          <div class="col-12 col-lg-4 mb-5 order-lg-1">
            <div class="bookpreview">
              <img class="thumb"
                   :src="booking.property && booking.property.images.length > 0 ? booking.property.images[0].medium : '/img/thumb01.jpg'">
              <div class="info">
                <h2>Booking Charges</h2>
                <hr>
                <div v-for="(charge, index) in booking.charges"
                     :key="index">
                  <ul v-if="charge.type === 1">
                    <li>{{ charge.description }} <strong>${{ charge.amount }}</strong></li>
                  </ul>
                </div>
                <hr>
                <div class="total">
                  Total <strong>${{ booking.amount }}</strong>
                </div>
                <hr>
                <p class="m-0"
                   v-if="booking && booking.brand && booking.payment_reference_code">
                  <strong>Paid with:</strong> <br>{{ booking.brand }} {{ booking.payment_reference_code }}
                </p>
                <button class="btn btn-primary"
                        type="button"
                        @click="validateBookingRequest"
                        v-if="booking && booking.status === 5"
                        :disable="!paymentVerification">
                  PAY NOW
                </button>
                <PaymentVerificationForm :payment-verification="paymentVerification"
                                         :get-user="getUser" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="resume mb-4">
              <div class="resume"
                   v-if="booking.property && booking.property.descriptions && booking.property.descriptions.length > 0">
                <div class="d-block mb-5">
                  <h4>Bedding Distribution</h4>
                  <hr>
                  <div class="scroll-bed-list">
                    <a href="javascript:;"
                       class="button-left"
                       @click="prevScroll()">
                      <i class="icon-cheveron-left" />
                    </a>
                    <ul class="bedding"
                        id="bedsList">
                      <li v-for="(room, index) in booking.property.rooms"
                          :key="index">
                        <div class="wrap-icons-beds">
                          <i class="icon-bed"
                             v-for="(bed, i) in room.beds"
                             :key="i" />
                        </div>
                        <strong>{{ room.room_type }} {{ parseInt(room.sequence) + 1 }}</strong>
                        <small v-for="(bed, i) in room.beds"
                               class="d-block pb-1"
                               :key="i">{{ bed.bed_quantity }} {{ bed.bed_name }}</small>
                      </li>
                    </ul>
                    <a href="javascript:;"
                       class="button-right"
                       @click="nextScroll()">
                      <i class="icon-cheveron-right" />
                    </a>
                  </div>
                </div>
                <div :class="{ paragraph: readMore }">
                  <p v-html="booking.property.descriptions[0].summary" />
                </div>
                <button @click="readMore = !readMore">
                  <i class="icon-cheveron-down" /> Read {{ readMore ? 'more' : 'less' }}
                </button>
                <!-- <router-link :to="{ name: 'property', params: { id: booking.property.id }}">
                  View more information
                </router-link> -->
              </div>
            </div>
            <hr>
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-md-7"
                   v-if="booking.property && booking.property.address">
                <address>
                  <strong>Address</strong><br>
                  {{ booking.property.address }}
                </address>
              </div>
              <!-- <div class="col-12 col-md-auto mb-3">
                <a href="#!"
                   class="btn btn-primary btn-block btn-sm">Add Airport Pickup</a>
              </div> -->
            </div>
            <div class="embed-responsive embed-responsive-21by9 mb-5"
                 v-if="booking.property && booking.property.lat && booking.property.lng">
              <GmapMap :center="{ lat: parseFloat(booking.property.lat), lng: parseFloat(booking.property.lng) }"
                       :zoom="17"
                       style="width: 100%; height: 300px"
                       class="embed-responsive-item">
                <GmapMarker :position="google && new google.maps.LatLng(parseFloat(booking.property.lat), parseFloat(booking.property.lng))"
                            icon="/img/MarkerMap.png"
                            :clickable="true"
                            :draggable="true"
                            @click="center=m.position" />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light pt-5 pb-3"
           v-if="booking && booking.property && booking.property.list_experiencies.length > 0">
        <div class="container">
          <h3 class="mb-4 min-title">
            Experiences & Excursions for your Trip
          </h3>
          <ul class="other-prop-list">
            <li v-for="(experience, index) in booking.property.list_experiencies"
                :key="index">
              <div class="item">
                <div class="thumbs">
                  <div class="img">
                    <img :src="experience.image.path">
                  </div>
                </div>
                <div class="info">
                  <h5>{{ experience.title }}<small>{{ experience.country.name }}</small></h5>
                  <hr>
                  <a href="javascript:;"
                     class="btn btn-primary btn-sm"
                     @click="openExperience(experience)">
                    Learn more
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal d-block"
           style="opacity: 1;"
           v-if="isModalOpen"
           id="activityModal">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    @click="isModalOpen = false;"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-slide">
              <div class="slide">
                <img :src="modalExperience.image.path"
                     class="img-fluid">
              </div>
              <!-- <div class="slide">
                <img data-flickity-lazyload="img/modal-slide01.jpg"
                     src="/img/modal-slide01.jpg">
              </div> -->
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 col-lg-7">
                  <h2>{{ modalExperience.title }} <small>{{ modalExperience.state.name }}</small></h2>
                </div>
                <div class="col-12 col-lg-7 modal-experience-description"
                     v-html="modalExperience.description" />
                <div class="col-1 justify-content-center d-none d-lg-flex">
                  <div class="h-100 border-left" />
                </div>
                <div class="col-12 col-lg">
                  <h3>Are you interested?</h3>
                  <!-- <p><small>US$50 / Traveler</small></p> -->
                  <router-link
                    class="btn btn-primary btn-sm"
                    :to="{ name: 'user-message', params: { id: this.$route.params.id }}">
                    Tell your Concierge
                  </router-link>
                  <!-- <hr>
                  <h3>Departure schedule:</h3>
                  <ul class="list-unstyled">
                    <li><small>8:00 AM</small></li>
                    <li><small>10:00 AM</small></li>
                    <li><small>2:00 PM</small></li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import { gmapApi } from 'vue2-google-maps';
import { mapGetters } from 'vuex';
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';
import PaymentVerificationForm from '../../components/PaymentVerificationForm.vue';

export default {
  name: 'UserTripOverview',
  components: {
    ProfileMenu,
    UserTripMenu,
    BookingInfo,
    PaymentVerificationForm
  },
  data() {
    return {
      // booking: {},
      readMore: true,
      verificationData: {},
      user: {},
      isModalOpen: false,
      modalExperience: {}
    };
  },
  computed: {
    google: gmapApi,
    booking() {
      return this.$store.getters.getBookingTrip;
    },
    paymentVerification() {
      return this.verificationData;
    },
    getUser() {
      return this.user;
    }
  },
  created() {
    this.user = this.$store.getters.getUser;
    this.$axios.get(`/v2/payments/verification?booking_id=${this.$route.params.id}`).then((bookingResponse) => {
      this.verificationData = bookingResponse.data.data;
    });
    // Promise.all([this.getBookingTrip()]).then((response) => {
    //   const verification = response[0];
    //   this.booking = verification;
    //   this.$axios.get(`/v2/payments/verification?booking_id=${this.$route.params.id}`).then((bookingResponse) => {
    //     this.verificationData = bookingResponse.data.data;
    //   });
    // });
  },
  mounted() {
    const alignetsac = document.createElement('script');
    alignetsac.setAttribute('src', 'https://vpayment.verifika.com/VPOS2/js/modalcomercio.js');
    document.head.appendChild(alignetsac);
  },
  methods: {
    validateBookingRequest() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line no-undef
          AlignetVPOS2.openModal('https://vpayment.verifika.com/');
        }
      });
    },
    ...mapGetters([
      'getBookingTrip',
    ]),
    prevScroll() {
      document.getElementById('bedsList').scrollLeft -= 50;
    },
    nextScroll() {
      document.getElementById('bedsList').scrollLeft += 50;
    },
    openExperience(experience) {
      this.isModalOpen = true;
      this.modalExperience = experience;
    }
  },

};
</script>

<style lang="scss" scoped>

</style>
